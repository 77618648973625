import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { NewServiceService } from 'src/app/SERVICE/new-service.service';
import { environment } from 'src/environments/environment';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {



  private clickSubject = new Subject<void>();

  clickEvent$ = this.clickSubject.asObservable();

  

  triggerClick() {
    this.clickSubject.next();
  }

  public Currency = { name: 'Indian Rupee', currency: 'INR', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products

  public products1: Observable<Product[]> = of([]);

  constructor(private http: HttpClient,
    private toastrService: ToastrService,   private service :NewServiceService) { }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    return this.products1;
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next.data) });
    return this.Products.data = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }


  productStore(productData: any): void {
    localStorage.setItem('products', JSON.stringify(productData));
    this.products1 = of(productData);
}

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products1.pipe(
        map(items => items.find(item => item.title.replace(' ', '-') === slug))
    );
}


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }












// Add to Cart
public addToCart(product): any {
  // Assuming `product` is the product being added to the cart
  console.log('apidata', product);

  // Find the cart item
  const cartItem = state.cart.find(item => item.productId === product.productId);

  // Log the found cart item or null if it doesn't exist
  console.log('cartItem111', cartItem ? cartItem : null);

  // Determine the quantity to add
  const qtyToAdd = product.quantity ? product.quantity : 1;
  
  // Calculate the current quantity in the cart
  const currentQty = cartItem ? cartItem.quantity : 0;
  const newQty = currentQty + qtyToAdd;
  
  // Log the stock of the product
  console.log('apiStock', product.stock);

  // Check stock availability
  const isStockAvailable = this.calculateStockCounts(product, newQty);
  console.log('stock', isStockAvailable);
  
  if (!isStockAvailable) return false;

  if (cartItem) {
    // Update existing cart item quantity
    cartItem.quantity = newQty;
    const cartData = {
      productId: cartItem.productId,
      productName: cartItem.title,
      quantity: cartItem.quantity,
      price: cartItem.price,
      totalPrice: cartItem.price * cartItem.quantity,
      userId: 1
    };
    console.log('already in', cartData);
    this.service.addToCard(cartData).subscribe(res => {
      console.log(res);
    });
  } else {
    // Add new product to cart
    state.cart.push({
      ...product,
      quantity: qtyToAdd
    });
    const lastElement = state.cart[state.cart.length - 1];
    const cartData = {
      productId: lastElement.productId,
      productName: lastElement.title,
      quantity: lastElement.quantity,
      price: lastElement.price,
      totalPrice: lastElement.price * lastElement.quantity,
      userId: 1
    };
    console.log('add new data', cartData);
    this.service.addToCard(cartData).subscribe(res => {
      console.log(res);
    });
  }
  
  this.OpenCart = true; // If we use cart variation modal
  localStorage.setItem("cartItems", JSON.stringify(state.cart));
  return true;
}

// Calculate Stock Counts
public calculateStockCounts(product, quantity) {
  console.log(product.quantity, 'product.quantity');
  console.log(quantity, 'quantity');
  
  const stock = product.stock;
  if (stock < quantity || stock === 0) {
    // this.toastrService.error(`You cannot add more items than available. In stock ${stock} items.`);
    return false;
  }
  return true;
}



new(data){
  console.log('CHECK WAS OK...!',data);
  state.cart = data
  console.log('CHECK WAS OK...!',state.cart);
  this.service.triggerClick();
}





























  // Update Cart Quantity
  // public updateCartQuantity(product: Product, quantity: number): Product | boolean {
  //   return state.cart.find((items, index) => {
  //     if (items.id === product.id) {
  //       const qty = state.cart[index].quantity + quantity
  //       const stock = this.calculateStockCounts(state.cart[index], quantity)
  //       if (qty !== 0 && stock) {
  //         state.cart[index].quantity = qty
  //       }
  //       localStorage.setItem("cartItems", JSON.stringify(state.cart));
  //       return true
  //     }
  //   })
  // }




    // Update Cart Quantity
  //   public updateCartQuantity(product: any, quantity: number): Product | boolean {
  //     return state.cart.find((items, index) => {
  //       console.log('product', product); 
  //     const cartItemIndex = state.cart.findIndex(item => item.productId == product.productId);
  //     if (cartItemIndex !== -1) {
  //         state.cart[cartItemIndex].quantity += quantity;
  //     } else {
  //         state.cart.push({
  //             ...product,
  //             quantity: quantity
  //         });
  //     }
  //     localStorage.setItem('cartItems', JSON.stringify(state.cart));
  //         return true
        
  //     })
  // }






  public updateCartQuantity(product: any, quantity: number): Product | boolean {
    const cartItemIndex = state.cart.findIndex(item => item.productId == product.productId);
    
    if (cartItemIndex !== -1) {
        // If the product is already in the cart, update its quantity
        const newQuantity = state.cart[cartItemIndex].quantity + quantity;
        
        // Check if the new quantity exceeds the available stock
        if (newQuantity > product.stock) {
            // If it exceeds, set the quantity to the available stock
            state.cart[cartItemIndex].quantity = product.stock;
        } else {
            // Otherwise, update the quantity as intended
            state.cart[cartItemIndex].quantity = newQuantity;
        }
        
        // If the quantity becomes zero or negative, remove the item from the cart
        if (state.cart[cartItemIndex].quantity <= 0) {
            state.cart.splice(cartItemIndex, 1);
        }
    } else {
        // If the product is not in the cart, and the quantity is positive and within stock, add it
        if (quantity > 0 && quantity <= product.stock) {
            state.cart.push({
                ...product,
                quantity: quantity
            });
        }
    }

    localStorage.setItem('cartItems', JSON.stringify(state.cart));
    
    return true;
}












    // Calculate Stock Counts
    // public calculateStockCounts(product, quantity) {
    //   console.log(product.quantity,'product.quantity');
    //   console.log(quantity,'quantity');
      
    //   const qty = product.quantity + quantity
    //   console.log(qty,'qty');
      
    //   const stock = product.stock
    //   if (stock < qty || stock == 0) {
    //     this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
    //     // this.toastrService.error('You can not add more items than available. In stock '+ 10 +' items.');
    //     return false
    //   }
    //   return true
    // }
  
    // Remove Cart items
    public removeCartItem(product: Product): any {
      const index = state.cart.indexOf(product);
      state.cart.splice(index, 1);
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
      return true
    }

  // Total amount 
  // public cartTotalAmount(): Observable<number> {
  //   return this.cartItems.pipe(map((product: Product[]) => {
  //     return product.reduce((prev, curr: Product) => {
  //       let price = curr.price;
  //       if(curr.discount) {
  //         price = curr.price - (curr.price * curr.discount / 100)
  //       }
  //       return (prev + price * curr.quantity) * this.Currency.price;
  //     }, 0);
  //   }));
  // }






  // public cartTotalAmount(): Observable<number> {
  //   return this.cartItems.pipe(map((products: Product[]) => {
  //     return products.reduce((total, product: Product) => {
  //       let price = product.price;
  //       if (product.discount) {
  //         price = product.price - (product.price * product.discount / 100);
  //       }
  //       return total + (price * product.quantity) * this.Currency.price;
  //     }, 0);
  //   }), map(totalAmount => Math.round(totalAmount))); // Round off the total amount
  // }











  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((products: Product[]) => {
        return products.reduce((total, product: Product) => {
          let price = product.price;

          console.log('price',price);
          
          if (product.discount) {
            price = product.price - (product.price * product.discount / 100);
          }
          return total + price * product.quantity * this.Currency.price;
        }, 0);
      }),
      map(totalAmount => Math.round(totalAmount)) // Only round the total amount once
    );
  }
  
  priceWithGst: any;
  public gstTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((products: Product[]) => {
        return products.reduce((total, product: Product) => {
          let price = product.price;
          if (product.discount) {
            price = product.price - (product.price * product.discount / 100);
          }
          const gst = price * 0.18;
          console.log("GST", gst);
          
          return total + gst * product.quantity;
        }, 0);
      }),
      map(totalGst => Math.round(totalGst))
    );
  }

  public getTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((products: Product[]) => {
        return products.reduce((total, product: Product) => {
          let price = product.price;
          if (product.discount) {
            price = product.price - (product.price * product.discount / 100);
          }
          //ven
          // const gst = price * 0.18 * product.quantity;
          // const priceWithGst = price * product.quantity + gst;

          //eng
          const priceWithGst = price * product.quantity;

          return total + priceWithGst * this.Currency.price;
        }, 0);
      }),
      map(totalAmount => Math.round(totalAmount)) // Round off the total amount
    );
  }

  public getTotalAmountWithShipping(shippingCharge: number): Observable<number> {
    return this.getTotalAmount().pipe(
      map(totalAmount => totalAmount + shippingCharge)
    );
  }
























  
  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product => 
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    } 
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) { 
      currentPage = 1; 
    } else if (currentPage > totalPages) { 
      currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage =  currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
