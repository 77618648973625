import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from './SERVICE/cookies.service';
import { CommunicationService } from './COMPONENTS/callService/service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private commService: CommunicationService,
  private cookieService: CookieService,
    private loader: LoadingBarService, translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
  }


  // constructor() {}

  
  ngOnInit() {

// this.cardRemove()


    // Setting a cookie
    this.cookieService.setCookie('exampleCookie', 'value123', 30);

    // Getting a cookie
    const cookieValue = this.cookieService.getCookie('exampleCookie');
    console.log('Cookie Value:', cookieValue);

    // Deleting a cookie
    this.cookieService.deleteCookie('exampleCookie');
  }

  cardRemove(){
    localStorage.removeItem('cartItems')

    setTimeout(() => {
      this.commService.triggerAction('some data');
    }, 1000);
  
  }

}
