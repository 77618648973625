import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';
import { NewServiceService } from 'src/app/SERVICE/new-service.service';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'fashion'; // Default Fashion

  // public products : Product[] = [];
  public products : any[] = []
  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService , private service : NewServiceService) { 
    // this.productService.getProducts.subscribe(response => 
    //   this.products = response.filter(item => item.type == this.type)
    // );


    // this.service.productList().subscribe((res:any) => {
    //   console.log(res.data);
    //   this.products = res.data
    // })

    // this.productService.products1.subscribe(res =>{
    //   console.log('notest',res);
    //   this.products = res
    // })


    this.service.productList().subscribe((res:any) => {
      console.log(res);
      this.products = res.data
    })
  }

  ngOnInit(): void {
    console.log(this.products);
    
  }

}
