import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface DealerDto {
  dealerName: string;
  email: string;
  password: string;
  phoneNumber: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  collaborationPreference: string;
  websiteUrl: string;
  productInterest: string;
  helpRequest: string;
}

@Injectable({ providedIn: 'root' }) // Move the decorator here
export class NewServiceService {


  private clickSubject = new Subject<void>();

  clickEvent$ = this.clickSubject.asObservable();

  

  triggerClick() {
    this.clickSubject.next();
  }

  

  private baseURL = environment.baseUrl;

  private apiUrl = 'https://apiv2.shiprocket.in/v1/external/courier/serviceability/';
  private token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ1OTEyMDEsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE2MTgyODkxLCJqdGkiOiJoa0ZtOFhvNVIyTVFyb2w5IiwiaWF0IjoxNzE1MzE4ODkxLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNTMxODg5MSwiY2lkIjo0MzgyMzU1LCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6InNob3BpZnkifQ.rSXYA3d4ATgDQxtTuvxjPnf2EoH7e37ATtAloXAF6-c';

  constructor(private http: HttpClient) {}

  checkNumber(phoneNumber:any) {
    return this.http.get(`${this.baseURL}whatsappNumber/checking?phoneNumber=${phoneNumber}`);
  }

  productList(){
return this.http.get(`${environment.baseUrl}product/list?limit=10&offset=0`)
  }


  orderCreate(order_data){
    return this.http.post(`${this.baseURL}order/create`,order_data)
  }


  addToCard(add_data){
    return this.http.post(`${this.baseURL}cart/create`,add_data)
  }

  productView(productID){
    return this.http.get(`${environment.baseUrl}product/view?productId=${productID}`)
  }


  // getServiceability(payload: any): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${this.token}`
  //   });
  //   let params = new HttpParams();
  //   params = params.append('pickup_postcode', payload.pickup_postcode);
  //   params = params.append('delivery_postcode', payload.delivery_postcode);
  //   params = params.append('weight', payload.weight);
  //   params = params.append('declared_value', payload.declared_value.toString());
  //   params = params.append('cod', payload.cod.toString());

  //   return this.http.get<any>(this.apiUrl, { headers, params });
  // }


  getServiceability(shippingData){
    return this.http.post(`${this.baseURL}shiprocket/calculate`,shippingData)
  }

  submitDealerForm(dealer: DealerDto){
    return this.http.post(`${this.baseURL}dealer/create`,dealer)
  }


  //login API --

  createUser(user_data:any){
    return this.http.post(`${this.baseURL}signup/create`,user_data)
     }



     

  logIn( user_name , password){
    // http://192.168.1.11:8083/user/login?email=kayalpetalautomations@gmail.com&password=1234567
    return this.http.get(`${environment.baseUrl}user/login?email=${user_name}&password=${password}`)
  }

  forgetPassword(mail:any){
    return this.http.get(`${environment.baseUrl}user/forget-password?email=${mail}`)
  }

  resetPassword(mail,password){
    http://localhost:8001/user/reset-password?email=kayalpetalautomations@gmail.com&password=1234567--- reset password
    return this.http.get(`${environment.baseUrl}user/reset-password?email=${mail}&password=${password}`)
  }


}
