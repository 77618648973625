<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our Petals store</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: +91 452 439 6547</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown ">
            <li class="onhover-dropdown mobile-account">
           <i class="fa fa-user h3 me-0 me-sm-0 me-md-4" aria-hidden="true" *ngIf="!this.userName"></i><i class="fa fa-user h3" aria-hidden="true" *ngIf="this.userName"></i> <span *ngIf="this.userName">{{this.userName}}</span> 
              <ul class="onhover-show-div border text-center ">
                <li *ngIf="data == null"  class="">
                  <a class=""  [routerLink]="['/pages/login']">
                    Login
                 </a></li>
                <li>
                  <a *ngIf="data != null" class=" " data-lng="es" (click)="logout()" [routerLink]="['/pages/login']">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home/welcome" id="headerlogo">
                <img src="../../../../assets/new_image/Logo-2.png" class="img-fluid petalLogo" alt="logo">
              
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
















