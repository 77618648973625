import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loader-page',
  templateUrl: './loader-page.component.html',
  styleUrl: './loader-page.component.scss'
})
export class LoaderPageComponent  implements OnInit {
  title :any;
  message:any;
  constructor(public dialogRef: MatDialogRef<LoaderPageComponent>) {}

  ngOnInit() {
  }

}