<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<!-- <section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Petal By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section> -->
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<!-- .petalLogo {
						width: 100%;
						height: 100px;
					  } -->
					<div class="footer-contant">
						<div class="footer-logo">
							<img src="../../../../assets/new_image/Logo-2.png" alt="logo" style="height: 100px;" >
						</div>
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p> -->
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/petalautomations/"><i class="fa fa-facebook" aria-hidden="true"></i> </a>
                                </li>
                                <li>
                                    <a href="https://thepetalautomations.com/"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="https://twitter.com/Petals_Auto"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/petals_automation/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/86271057/admin/feed/posts/"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<!-- <div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Womens Fashion</a></li>
                                <li><a href="javascript:void(0)">Mens Fashion</a></li>
                                <li><a href="javascript:void(0)">Kids Fashion</a></li>
                                <li><a href="javascript:void(0)">Featured</a></li>
                            </ul>
                        </div>
					</div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <!-- <h4>why we choose</h4> -->
                        </div>
                        <div class="footer-contant">
                            <ul>
								
                                <li><a [routerLink]="'/pages/shopping_policy'">shipping & return</a></li>
                                <li><a [routerLink]="'/pages/secure_shopping'">secure shopping</a></li>
                                <!-- <li><a [routerLink]="'/pages/gallary'">gallary</a></li> -->
                                <li><a [routerLink]="'/pages/affiliate'">affiliates</a></li>
                                <li><a [routerLink]="'/pages/contact'">contacts</a></li>
								<li><a [routerLink]="'/pages/faq'">FAQ</a></li>
                            </ul>
							
                        </div>
					</div>
				</div>


				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4 *ngIf="">why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
							
								<li><a [routerLink]="'/pages/Privacy_policy'">Privacy & Policy</a></li>
								<li><a [routerLink]="'/pages/terms_condition'">Terms & Condition</a></li>
								<li><a [routerLink]="'/pages/Refund_policy'">Refund Policy</a></li>
								<li><a [routerLink]="'/pages/reseller'">Become a Reseller</a></li>
                            </ul>
							
                        </div>
					</div>
				</div>



				<div class="col-12 col-sm-12 col-md-4">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>store information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i><div>Corporate Office</div>
									<div>No.155, LIG Colony </div>
									<div>K.K.Nagar, Madurai,
										India.</div>
									</li>
						        <li><i class="fa fa-phone"></i>Call :<a href="tel:4524396542"> +91 4524396542</a></li>
								<li><i class="fa fa-phone"></i>Phone :<a href="tel:9360132690"> +91 9360132690</a></li>
						        <li><i class="fa fa-envelope-o"></i>Email : <a href="mailto:contact&#64;petalrobo.com" style="text-transform: lowercase">{{ emil}}</a></li>
						        <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- <div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} theme forest powered by pixelstrap</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div> -->
</footer>
<!--footer section end -->