<div class="text-center p-2 ">
  
    <div mat-dialog-content [style.width]="'50'">
        <mat-spinner  [style.margin]="'auto'" [color]="'warn'" [style.overflow-x]="'hidden'"></mat-spinner>
    </div>
</div>

<!-- <div fxLayout="row wrap" class="row mt-16">
    <div fxFlex="100" fxFlex.gt-xs="100" class="text-center">
        <img src="assets/images/loader.gif" width="200" height="200" alt="logo" class="" />
    </div>
</div> -->