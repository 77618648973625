// Home Slider
export let HomeSlider: any = {
    loop: true,
    nav: true,
    dots: false,


    autoplay: false, // Auto slide
    autoplayTimeout: 9000, // Autoplay interval in milliseconds (6 seconds)
    autoplayHoverPause: true, // Pause on hover
    autoplaySpeed: 1500, // Slide transition speed in milliseconds (1.5 seconds)


    navContainerClass: 'owl-nav',
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>' ],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },
};

// Blog Slider
export let BlogSlider: any = {
    loop: true,
    nav: true,
    dots: false,


    autoplay: true, // Auto slide
    autoplayTimeout: 6000, // Autoplay interval in milliseconds (6 seconds)
    autoplayHoverPause: true, // Pause on hover
    autoplaySpeed: 1500, // Slide transition speed in milliseconds (1.5 seconds)


    navContainerClass: 'owl-nav',
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>' ],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },
};

// Insta Slider
export let InstaSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        740: {
            items: 3
        },
        940: {
            items: 6
        },
        1200: {
            items: 6
        }
    }
};

// Logo Slider
export let LogoSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        767: {
            items: 5
        },
        576: {
            items: 4
        },
        480: {
            items: 3
        },
        0: {
            items: 2
        }
    }
};

// Collection Slider
export let CollectionSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        991: {
            items: 4 
        },
        767: {
            items: 3    
        },
        586: {
            items: 2
        },
        0: {
            items: 1
        }
    }
};

// Category Slider
export let CategorySlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        1024: {
            items: 6
        },
        767: {
            items: 5
        },
        576: {
            items: 5
        },
        480: {
            items: 3
        },
        0: {
            items: 2
        }
    }
};

// Testimonial Slider
export let TestimonialSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        991: {
            items: 2
        },
        0: {
            items: 1
        }
    }
}

// Team Slider
export let TeamSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        991: {
            items: 4
        },
        767: {
            items: 3
        },
        586: {
            items: 2
        },
        0: {
            items: 2
        }
    }
}

// Compare Slider
export let  CompareSlider: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        991: {
            items: 4
        },
        767: {
            items: 3
        },
        586: {
            items: 2
        },
        0: {
            items: 1
        }
    }
}

// Product Slider
export let ProductSlider: any = {
     loop: true,
    nav: false,
    dots: true,


    autoplay: false, // Auto slide
    autoplayTimeout: 6000, // Autoplay interval in milliseconds (6 seconds)
    autoplayHoverPause: false, // Pause on hover
    autoplaySpeed: 1500,
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },
}

export let ProductSliderHome: any = {
    loop: false,
    dots: true,
    navSpeed: 300,
    autoHeight:true,
    responsive: {
        1200: { 
            items: 5
        },
        991: {
            items: 4
        },
        767: {
            items: 3
        },
        420: {
            items: 2
        }, 
        0: {
            items: 1
        }
    }
}

// Product Slider
export let ProductOneSlider: any = {
    items: 1,
    loop: true,
    dots: false,
    navSpeed: 300
}

// New Product Slider
export let NewProductSlider: any = {
    items: 1,
    loop: true,
    
    dots:false,
    navContainerClass: 'owl-nav',
    navClass: [ 'owl-prev', 'owl-next' ],
    navText: [ '<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>' ],
}

// Product Details Main Slider
export let ProductDetailsMainSlider: any = {
    items: 1,
  nav: true,
  dots: true,
  autoplay: true,
  slideSpeed: 300,
  loop: true,
  navContainerClass: 'owl-nav', // Optional custom navigation container clas
  navClass: ['owl-prev', 'owl-next'], // Optional custom navigation arrow classes
  responsive: {
    0: {    
      items: 1
    },
    600: {
      items: 2
    },
    1000: {
      items: 3
    }
  }
}

// Product Details Thumb Slider
export let ProductDetailsThumbSlider: any = {
    items: 3,
    // navContainerClass: 'owl-nav',
    // navClass: [ 'owl-prev', 'owl-next' ],
    // navText: [ '<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>' ],
    loop: true,
    margin: 10,
    autoplay: false, // Auto slide
    autoplayTimeout: 6000, // Autoplay interval in milliseconds (6 seconds)
    autoplayHoverPause: true, // Pause on hover
    autoplaySpeed: 1500, 
    dots: true,
    // nav: true,
}